export enum InlineCommentError {
	INVALID_SELECTION = 'Invalid text selection. Cannot create inline comments on macros (mentions, images, emojis, etc.)',
	FAILED_TO_INSERT_MARK = 'Failed to insert highlight, invalid originalSelection, numMatches, or matchIndex',
	INVALID_ARGUMENT_CREATED_FROM = 'Invalid createdFrom, please specify if created from editor or renderer',
	STALE_SELECTION = 'Selection was made against a stale version of the content',
	NO_ANONYMOUS_ACCESS = 'Anonymous access is not permitted',
	// Create Reply
	CREATE_COMMENT_NOT_FOUND = 'Content with id %d for comment container is not found. Please provide correct container id.',
	PARENT_NOT_FOUND = 'Parent comment with id %d is not found. Please provide correct parent comment id.',
	INCOMPATIBLE_PARENT_TYPE = 'Incompatible parent type. Please provide comment id.',
	CANNOT_REPLY_TO_DELETED_PARENT = 'Cannot add reply to a deleted comment',
	CANNOT_REPLY_TO_RESOLVED_COMMENT = 'Inline comment in RESOLVED status cannot be replied',
	// Delete
	DELETE_ERROR = 'Exception occurred while deleting comment with id',
	NO_DELETE_PERMS = 'Parent page view is restricted',
	NO_DELETE_WITH_CHILDREN = 'Inline comment with child cannot be deleted.',
	COMMENT_ALREADY_DELETED = 'Exception occurred while deleting comment with id %d',
	// Edit
	NO_UPDATE_PERMS = 'Could not update Content of type  = class com.atlassian.confluence.pages.Comment with id %d',
	UPDATE_COMMENT_NOT_FOUND = 'Could not find Comment for update with id CommentId{id=%d}',
	CANNOT_UPDATE_RESOLVED_COMMENT = 'Inline comment in RESOLVED status cannot be updated',
	// Create
	NO_CREATE_PERMS = 'Could not create content with type comment',
	INVALID_ARGUMENT_MATCHES = 'Invalid arguments provided for matchIndex and/or numMatches',
	CONTENT_OUT_OF_DATE = 'Published Version out of date. Please provide correct published version.',
	// Resolve
	CANNOT_RESOLVE_DELETED_COMMENT = '%d must be existing and be an inline comment',
}
