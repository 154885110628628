import React from 'react';

import Spinner from '@atlaskit/spinner/spinner';

import { LoadableLazy } from '@confluence/loadable';

import { LazyCommentEditorPlaceholder } from './LazyCommentEditorPlaceholder';

export const LazyCommentEditor = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CommentEditor" */ './CommentEditor'))
			.CommentEditor,
	loading: () => (
		<div>
			<Spinner size="medium" />
		</div>
	),
});

export const LazyInlineCommentEditor = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CommentEditor" */ './CommentEditor'))
			.CommentEditor,
	loading: () => <LazyCommentEditorPlaceholder />,
});

export const LazyCommentEditorWithoutSpinner = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CommentEditor" */ './CommentEditor'))
			.CommentEditor,
});
