import { defineMessages } from 'react-intl-next';

export const MAX_UNCOLLAPSED_REPIES = 5;
export const STICKY_COMMENT_VERTICAL_OFFSET = 130;

export const i18n = defineMessages({
	closeCommentBox: {
		id: 'annotation-provider-inline-comments.closeCommentBox',
		defaultMessage: 'Close inline comments',
		description: 'Button close inline comments',
	},
	closeSidebarTooltip: {
		id: 'annotation-provider-inline-comments.close.icon.tooltip',
		defaultMessage: 'Close sidebar',
		description: 'A tooltip message informing the user what the icon does on hover and its hotkey',
	},
});
