import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { PageMode } from './enums';

export const useGetPageMode = () => {
	const isLivePage = useIsLivePage();
	const isEditorPage = useIsEditorPage();

	let pageMode = PageMode.VIEW;
	if (isLivePage) {
		pageMode = PageMode.LIVE;
	} else if (isEditorPage) {
		pageMode = PageMode.EDIT;
	}
	return pageMode;
};
