import gql from 'graphql-tag';

export const AnnotationStateQuery = gql`
	query AnnotationStateQuery($pageId: ID!, $contentStatus: [GraphQLContentStatus]) {
		comments(pageId: $pageId, contentStatus: $contentStatus, type: UNRESOLVED, depth: ROOT) {
			nodes {
				id
				location {
					type
					... on InlineComment {
						inlineMarkerRef
						inlineResolveProperties {
							resolved
						}
					}
				}
			}
		}
	}
`;
