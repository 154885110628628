import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

export const CreateInlineCommentMutation = gql`
	mutation CreateInlineCommentMutation($input: CreateInlineCommentInput!) {
		createInlineComment(input: $input) {
			...inlineCommentFragment
			replies {
				...inlineCommentFragment
			}
		}
	}

	${inlineCommentFragment}
`;
