import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import { getLogger } from '@confluence/logger';

import type { EditorCommentType, EditorCommentMode } from './commentTypes';

export const OUT_OF_DATE_KEY = 'outOfDate';

type DraftObject = {
	pageId: string;
	adf: string;
};

const logger = getLogger('comment-draft-utils');

const getKey = (type: EditorCommentType, mode: EditorCommentMode) => {
	switch (type) {
		case 'edit-inline':
			if (mode === 'create') {
				return keys.COMMENT_DRAFTS_EDITOR_INLINE_CREATE;
			} else if (mode === 'edit') {
				return keys.COMMENT_DRAFTS_EDITOR_INLINE_EDIT;
			} else {
				return keys.COMMENT_DRAFTS_EDITOR_INLINE_REPLY;
			}
		case 'inline':
			if (mode === 'create') {
				return keys.COMMENT_DRAFTS_INLINE_CREATE;
			} else if (mode === 'edit') {
				return keys.COMMENT_DRAFTS_INLINE_EDIT;
			} else {
				return keys.COMMENT_DRAFTS_INLINE_REPLY;
			}
		case 'page':
		default:
			if (mode === 'create') {
				return keys.COMMENT_DRAFTS_PAGE_CREATE;
			} else if (mode === 'edit') {
				return keys.COMMENT_DRAFTS_PAGE_EDIT;
			} else {
				return keys.COMMENT_DRAFTS_PAGE_REPLY;
			}
	}
};

export const saveCommentDraft = (
	adf: string,
	pageId: string,
	type: EditorCommentType,
	mode: EditorCommentMode,
) => {
	let b64EncodedDraftObj: string | null = null;
	const key = getKey(type, mode);
	const draftObj = JSON.stringify({ pageId, adf });
	try {
		b64EncodedDraftObj = btoa(draftObj);
	} catch (e) {
		logger.error`Unable to save draft object for ${pageId}; ${e}`;
	}

	if (b64EncodedDraftObj === null) {
		return false;
	}

	// Store the draft with a 72 hour expiration
	localStorage.setItem(key, b64EncodedDraftObj, 259200);
	return true;
};

export const clearCommentDraft = (type: EditorCommentType, mode: EditorCommentMode) => {
	const key = getKey(type, mode);

	localStorage.removeItem(key);
};

export const hasCommentDraft = (pageId: string, type: EditorCommentType, mode: EditorCommentMode) =>
	Boolean(getCommentDraft(pageId, type, mode));

export const getCommentDraft = (
	pageId: string,
	type: EditorCommentType,
	mode: EditorCommentMode,
) => {
	let draftObj: DraftObject | null = null;
	const key = getKey(type, mode);
	const b64EncodedDraftObj = localStorage.getItem(key);

	if (b64EncodedDraftObj !== null) {
		try {
			draftObj = JSON.parse(atob(b64EncodedDraftObj));
		} catch (e) {
			logger.error`Unable to get draft object for ${pageId}; ${e}`;
		}

		if (draftObj) {
			const { pageId: draftPageId, adf } = draftObj;

			// If the pageId matches, return the ADF, otherwise return null
			if (draftPageId === pageId) {
				return adf;
			}
		}
	}

	return null;
};
