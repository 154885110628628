import type { Mark } from '@atlaskit/editor-prosemirror/model';
import type { RendererActions } from '@atlaskit/renderer/actions';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { AddNodeMarkStep } from '@atlaskit/editor-prosemirror/transform';
import { AddMarkStep, RemoveMarkStep } from '@atlaskit/editor-prosemirror/transform';

import { type CreateInlineCommentStep } from '@confluence/inline-comments-queries';

import { getRendererAnnotationStates } from './stateResolver';

interface RendererAnnotationsProps {
	rendererActions: RendererActions;
	contentId: string;
	triggerAnalyticsEvent?: (
		eventName: string,
		extraData?: {
			[key: string]: any;
		},
	) => Promise<void>;
	updateUnresolvedInlineComment: (unresolvedInlineComments: string[]) => void;
}

export type RendererInlineCommentsPubSubFn = {
	contentId?: string;
	contentType?: 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed';
	rendererActions?: any;
	updateDocument: (newAdf: JSONDocNode) => void;
	updateUnresolvedInlineComment: (unresolvedInlineComments: string[]) => void;
};

export const updateRendererAnnotations = async ({
	rendererActions,
	contentId,
	triggerAnalyticsEvent,
	updateUnresolvedInlineComment,
}: RendererAnnotationsProps) => {
	const allAnnotationMarks = rendererActions?.getAnnotationMarks();
	const allAnnotationIds = allAnnotationMarks?.map((mark: Mark) => mark.attrs.id) || [];

	getRendererAnnotationStates({
		annotations: allAnnotationIds,
		pageId: contentId,
		triggerAnalyticsEvent,
		validAnnotationsCallback: updateUnresolvedInlineComment,
		isNestedRender: false,
	}).catch(() => {
		//intentional no-op
	});
};

export const constructStepForGql = (
	step: AddMarkStep | AddNodeMarkStep,
	pos?: number,
): CreateInlineCommentStep => {
	// AddMarkStep has additional properties that aren't allowed in the mutation variables
	const markInfo = {
		mark: {
			attrs: {
				annotationType: 'inlineComment',
				id: step.mark.attrs.id,
			},
			type: 'annotation',
		},
	};

	// TODO: remove this logic since pos should be read from step
	if (pos !== undefined) {
		return {
			pos,
			...markInfo,
		};
	}

	if (step instanceof AddMarkStep || step instanceof RemoveMarkStep) {
		const { from, to } = step;
		return {
			from,
			to,
			...markInfo,
		};
	}

	return {
		pos: step.pos,
		...markInfo,
	};
};
