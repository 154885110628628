import gql from 'graphql-tag';

export const PageInfoQuery = gql`
	query PageInfoQuery($pageId: ID) {
		content(id: $pageId, status: ["current", "archived", "draft"]) {
			nodes {
				id
				type
				space {
					id
				}
				operations {
					operation
					targetType
				}
				version {
					number
				}
			}
		}
	}
`;
